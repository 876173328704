
import {
  ref
} from 'vue'
import {
  useRoute
} from 'vue-router'
import {
  useStore
} from 'vuex'
import Price from '@/views/master/transactions/price/Price.vue'
import PriceHistory from '@/views/master/transactions/price/PriceHistory.vue'
import mitt from 'mitt'

export default {
  components: {
    Price,
    PriceHistory
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const currentTab = ref(0)
    // const emitter = mitt()
    const priceComp = ref()
    const inputSearch = (null)
    const listPrice = ref()
    const showingModal = () => {
      priceComp.value.redirectAdd()
    }
    // const wow = (e: any) => {
    //   console.log('wowo keren', e)
    // }
    const changeSearch = (val: any) => {
      priceComp.value.changeSearch(val)
    }
    // onMounted(() => {
    //   // emitter.on('foo', wow)
    //   emitter.on('bar', wow)
    // })
    return {
      route,
      store,
      currentTab,
      priceComp,
      showingModal,
      changeSearch,
      inputSearch
    }
  },
}
