import {
  ref, onMounted, getCurrentInstance, reactive, toRefs, watch, computed
} from 'vue'
import { useStore } from 'vuex'
import { priceUseCase } from '@/domain/usecase'
import { refObs } from '@/composables/observables'
import { debounceTime, filter, startWith } from 'rxjs/operators'
import moment from 'moment'
import PriceForm from '@/views/master/transactions/price/PriceForm.vue'
import { useRouter, useRoute } from 'vue-router'
import mitt from 'mitt'

export default {
  components: {
    PriceForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const emitter = mitt()
    const route = useRoute()
    const store = useStore()
    const dataEdit = ref(null)
    const data = ref([])
    const sliderActive = ref(false)
    const op = ref()
    const sortOp = ref()
    const inputSearch = refObs('')
    const id = ref('')
    const onCopy = ref(false)
    const onClone = ref(false)
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
    }
    const sortingValue = ref('CreateDate desc')
    const sortingOption = [
      { name: 'Tanggal Membuat', value: 'CreateDate desc', key: 'sel1' },
      { name: 'Last Update', value: 'LastUpdate desc', key: 'sel2' }
    ]
    const redirectAdd = () => {
      dataEdit.value = null
      id.value = ''
      onCopy.value = false
      sliderActive.value = true
    }

    const redirectEdit = (val: any) => {
      dataEdit.value = val
      id.value = val.Id
      onCopy.value = false
      sliderActive.value = true
    }

    const clonePrice = () => {
      onClone.value = true
      sliderActive.value = true
    }

    const redirectDetail = (idData: any) => {
      router.push({ name: 'master-harga-detail', params: { id: idData } })
    }

    const showDropDown = (evt: any, val: any) => {
      if (val === null) {
        sortOp.value.toggle(evt)
      } else {
        dataEdit.value = val
        id.value = val.Id
        op.value.toggle(evt)
      }
    }

    const showSidebar = () => {
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const duplicatePrice = () => {
      onCopy.value = true
      sliderActive.value = true
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await priceUseCase.getAllPrice(filters)
      console.log('res', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        if (response.result.Data.length === 0 && response.result.HasPreviousPage) {
          filters.pageNumber = 1
          getAllData()
        }
        filters.totalRecords = response.result.Count
        data.value = response.result.Data
      }
      store.dispatch('hideLoading')
    }

    const changeSorting = async (val: any) => {
      sortingValue.value = await val
      getAllData()
    }

    const onPage = (event: any) => {
      console.log('evt', event)
      filters.pageNumber = event.page + 1
      getAllData()
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await priceUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Harga',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await priceUseCase.deleteData(id.value)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus harga, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    const changeSearch = (val: any) => {
      filters.search = val
      filters.pageNumber = 1
      filters.pageSize = 10
      console.log('searhiing val', val)
      getAllData()
    }
    const wow = (e: any) => {
      console.log('bar masukkk', e)
    }
    const cobaEmit = () => {
      emitter.emit('bar', { bariq: 'yes' })
    }
    onMounted(() => {
      // emitter.on('bar', wow)
      getAllData()
    })

    // inputSearch.pipe(
    //   startWith((val: any) => val.length > 2),
    //   filter((val) => val.length > 2 || val === ''),
    //   debounceTime(500)
    // ).subscribe((val: any) => {
    //   getAllData(val)
    // })

    return {
      op,
      data,
      inputSearch,
      dataEdit,
      moment,
      sliderActive,
      onCopy,
      filters,
      onPage,
      getAllData,
      redirectAdd,
      redirectEdit,
      showSidebar,
      closeSidebar,
      showDropDown,
      changeIsActive,
      deleteData,
      redirectDetail,
      duplicatePrice,
      sortOp,
      sortingValue,
      sortingOption,
      route,
      changeSorting,
      onClone,
      clonePrice,
      emitter,
      cobaEmit,
      changeSearch,
    }
  }
}
