import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  priceUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'

export default {
  setup() {
    const store = useStore()
    const data = ref([])
    const filters = {
      expand: '&$expand=Basis($select=Kode)',
      sorting: 'TanggalBerlaku desc',
      select: '&$select=Kode,TanggalBerlaku'
    }
    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await priceUseCase.getAll(filters)
      data.value = response.result.map((x: any, index: any) => {
        x.index = index + 1
        return x
      })
      store.dispatch('hideLoading')
    }
    onMounted(() => {
      getAllData()
    })
    return {
      data,
      moment
    }
  }
}
